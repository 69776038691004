<script setup lang="ts">
import { relsInjectKey } from "@/helpers/injectKeys";

const route = useRoute();
const { locale, locales } = useI18n();
const storyblokApi = useStoryblokApi();
const { slug } = route.params;
  
const isValueMatchingALocale = (value: string) => {
  return locales.value.some((l) => {
    if (typeof l == "object") return l.code === value;
    else return l === value;
  });
};

// filter out language params of stories because they should not be part of story route but of story language query param
// set story name 'home' for home routes
const slugWithoutLanguage = computed(() => {
  if (Array.isArray(slug)) {
    const withoutLang = slug
    .filter((x: string) => !isValueMatchingALocale(x))
    .join("/");
    return withoutLang?.length > 0 ? withoutLang : "home";
  } else {
    return isValueMatchingALocale(slug) || slug?.length === 0 ? "home" : slug;
  }
});

const stateKey = locale.value + "/" + slugWithoutLanguage.value;
const story = useState(stateKey, () => shallowRef<StoryblokStory>());
const rels = useState(stateKey + "/rels", () => shallowRef<Array<StoryblokStory>>());

const loadStory = async () => {
  const resolveRelations = "AppartementReference.appartements";

  try {
    const response = await storyblokApi.get(`cdn/stories/${slugWithoutLanguage.value}`, {
      ...useStoryblokOptions(),
      resolve_relations: resolveRelations,
    });

    if (response.data) {
      if (response?.data?.story) {
        story.value = response?.data?.story;
      }
  
      if (response?.data?.rels) {
        rels.value = response?.data?.rels;
      }
    }

  } catch(err: unknown) {
    const parsedError = JSON.parse(err as string) as TStoryBlokError;

    throw createError({
      statusCode: parsedError.status,
      statusMessage: parsedError.response + ` /${slugWithoutLanguage.value}`,
      fatal: true,
    });
  }
};

onMounted(() => {
  if (story.value) {
    useStoryblokBridge(story.value.id, (evStory) => (story.value = evStory));
  }
});

if (!story.value) {
  await loadStory();
}

provide(relsInjectKey, rels.value);

useSeoMeta({
  title: story.value?.content.title,
  description: story.value?.content?.description,
});
</script>

<template>
  <div>
    <StoryblokComponent
      v-if="story"
      :blok="story.content"
      :key="story.content._uid"
    />
  </div>
</template>
